import React from "react";

import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PhotoCard = ({ node }) => {
  const image = getImage(node);
  return (
    <>
      <div className="w-full h-full">
        {/* <a href={node.publicURL}> */}
        <GatsbyImage image={image} alt={node.name} />
        {/* </a> */}
      </div>
    </>
  );
};

export default PhotoCard;
