import React from "react";

import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Pagination from "../components/pagination";
import PhotoCard from "../components/photo-card";

const PhotoList = ({ data, location }) => {
  const photos = data.photos.nodes;
  const itemCount = data.photos.pageInfo.itemCount;
  const siteTitle = data.site.siteMetadata.title;
  const photoPath = data.site.siteMetadata.photoPath;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All photos" />

      <Pagination
        currentPage={data.photos.pageInfo.currentPage}
        totalPages={data.photos.pageInfo.pageCount}
        totalItems={data.photos.pageInfo.totalCount}
        itemsPerPage={data.photos.pageInfo.perPage}
        path={`${photoPath}`}
      />

      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 pt-5 lg:pt-0 mt-2">
        {itemCount > 0 && (
          <>
            {photos.map(node => (
              <PhotoCard node={node} key={node.id} />
            ))}
          </>
        )}
      </div>

      <Pagination
        currentPage={data.photos.pageInfo.currentPage}
        totalPages={data.photos.pageInfo.pageCount}
        totalItems={data.photos.pageInfo.totalCount}
        itemsPerPage={data.photos.pageInfo.perPage}
        path={`${photoPath}`}
      />

      <p className="mt-4 text-sm font-semibold text-gray-600 dark:text-white">
        Total {data.photos.pageInfo.totalCount} photos.
      </p>
    </Layout>
  );
};

export default PhotoList;

export const pageQuery = graphql`
  query imagePageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        photoPath
      }
    }
    photos: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        sourceInstanceName: { in: ["photos", "blog"] }
      }
      limit: $limit
      skip: $skip
      sort: { fields: name, order: ASC }
    ) {
      pageInfo {
        perPage
        itemCount
        totalCount
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
      nodes {
        name
        id
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            quality: 90
            aspectRatio: 1
          )
        }
        publicURL
      }
    }
  }
`;
