import React from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

const Pagination = ({
  currentPage,
  totalPages,
  totalItems,
  itemsPerPage,
  path,
}) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === totalPages;
  const prevPage = currentPage - 1 === 1 ? path : `${path}${currentPage - 1}`;
  const nextPage = `${path}${currentPage + 1}`;

  return (
    <>
      <nav className="mt-4 mb-2">
        <ul className="flex flex-wrap justify-between list-none p-0">
          <li>
            {!isFirst && (
              <Link
                to={prevPage}
                rel="prev"
                className="relative inline-flex items-center mr-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />{" "}
                Previous Page
              </Link>
            )}
          </li>
          <li className="flex items-center">
            <p className="text-sm text-gray-700 dark:text-white">
              Page <span className="font-medium">{currentPage}</span> /{` `}
              <span className="font-medium">{totalPages}</span>
            </p>
          </li>
          <li>
            {!isLast && (
              <Link
                to={nextPage}
                rel="next"
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Next Page{" "}
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
